import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import BlogTitle from "../components/blog-title";

const WritingList = ({ data }) => (
  <Layout writing={true} showHeader={true} showFooter={true}>
    <section>
      {data.allMarkdownRemark.edges.map(({ node }) => {
        return (
          <BlogTitle
            post={node}
            key={node.id}
            slug={node.fields.slug}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
          />
        );
      })}
    </section>
  </Layout>
);

export default WritingList;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
